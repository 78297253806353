@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
  overflow-y: auto;
}
/* ::-webkit-scrollbar {
  display: none;
} */

:root {
  --color-text: white;
  --color-text-alt: #343047;

  --color-background: white;
  --color-background: #343047;

  --color-background: white;
  --color-text: black;

  --bg-light: #fcfcfc;
  --bg-dark: black;
  --color-primary01: #256eff;
  --color-primary0: black;
  --color-primary1: #946bde;
  --color-primary: #946bde;
  --color-alternate: #bb342f;
  --color-secondary: #46237a;
  --color-white: white;
  --color-light: rgba(255, 255, 255, 0.6);
  --transition: all 400ms ease;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
  --max-width: 1300px;
  --transition: all 0.5s ease-in-out;
  --box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  --box-shadow: 0px 15px 20px rgba(1, 1, 1, 0.4);
}

.container {
  width: 100%;
  /* max-width: var(--max-width); */
  margin: 0 auto;
  background-color: transparent;
  transition: var(--transition);
  color: #256eff;
}
.container > div {
  max-width: 1300px;
  margin: 0 auto;
  /* min-height: 100vh; */
}
.btn {
  border: none;
  width: 100%;
  max-width: 15rem;
  max-height: 4rem;
  letter-spacing: 2.5px;
  padding: 15px 25px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.btn-primary {
  background-color: var(--color-alternate);
  color: #fcfcfc;
}
.btn-secondary {
  background-color: var(--color-primary1);
  color: #fcfcfc;
}
.btn-alternate {
  background-color: var(--color-alternate);
  color: #fcfcfc;
}
.btn-alternate2 {
  background-color: var(--color-primary0);
  color: #fcfcfc;
}

.btn-login {
  border: 1px solid grey;
  color: var(--color-primary);
}
.info-section-button a,
.info-section-button a:visited {
  color: white;
}

@media screen and (max-width: 900px) {
  .btn {
    font-size: 13px;
    width: 100%;
    min-width: 12rem;
    text-align: center;
  }
}
@media screen and (min-width: 900px) {
  .btn-primary:hover {
    background-color: #94b0da;
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    color: var(--color-primary0) !important;
    transform: translateY(-7px);
  }
  .btn-secondary:hover {
    background-color: #94b0da;
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    color: var(--color-primary0);
    transform: translateY(-7px);
  }
  .btn-alternate:hover {
    background-color: white;
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    color: var(--color-alternate);
    transform: translateY(-7px);
  }
  .btn-alternate2:hover {
    background-color: white;
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    color: var(--color-primary1);
    transform: translateY(-7px);
  }
  .btn-small:hover {
    background-color: var(--color-primary);
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    color: #fff;
    transform: translateY(-5px);
  }

  .info-section-button a:hover,
  .info-section-button a:visited:hover {
    color: var(--color-primary0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
