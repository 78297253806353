@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");

.supportPage-container {
  font-family: "Fira Sans", sans-serif;
  margin-top: 0px;
  min-height: 85vh;
  animation: fade-in 600ms ease-in-out;
}
.header-container {
  background-color: #2892d7;
  background-color: white;
}
.header {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  color: var(--color-primary);
}
.header h1 {
  font-size: 38px;
  color: var(--color-primary);
}
.header p {
  width: 300px;
  margin: 0 auto;
  line-height: 1.8em;

  font-weight: 700;
  letter-spacing: 1px;
}
.header-info {
  display: flex;
}
.header-image {
  width: 100%;
  align-items: center;
  margin: 0 auto;
  overflow-y: hidden;
}
.header-image img {
  height: 150px;
  padding: 10px;
}
.support-reasons {
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 5px;
}

.support-image {
  height: 300px;

  overflow-y: hidden;
  overflow-y: hidden;
}

.support-image img {
  overflow: hidden;
}
.support-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.support-text h2 {
  margin: 20px 0;
}
.support-text p,
.support-page-need-support {
  letter-spacing: 1px;
  line-height: 1.6em;
  padding: 0 10px;
}
.support-ways {
  max-width: 1300px;
  margin: 0px auto;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr;
}
.support-ways-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}
.support-ways-unit {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--color-primary);

  margin: 10px auto;
  width: 40%;
  min-width: 300px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(46, 104, 229, 0.4);
  transition: all 0.5s ease-in-out;
}
.support-ways-text p {
}
.sw-image {
  /* border: 1px solid crimson; */
  height: 125px;
  width: 125px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.extended {
  display: none;
}

.container-2 {
  height: fit-content;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.border {
  position: relative;
  height: 380px;
  width: 240px;
  background: transparent;
  border-radius: 10px;
  transition: all 1s;
  cursor: pointer;
}
.border:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #256eff67;
}
.wrapper {
  height: 600px;
  width: 400px;
  background: grey;
  border-radius: 10px;
  transition: all 1.2s ease-in-out;
  overflow: hidden;
  background: black;
  box-shadow: 0 7px 6px -6px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.card {
  background: url("https://api.wpmet.com/auth/wp-content/uploads/2021/06/about-us_img-1.png")
    no-repeat center center/cover;
  border: 1px solid var(--color-primary);
}
.card:hover {
  background: url("https://api.wpmet.com/auth/wp-content/uploads/2021/06/about-us_img-1.png")
    left no-repeat;
}
.card:hover h2 {
  opacity: 1;
}
.card:hover .fa-brands {
  opacity: 1;
}
.card h2 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  margin: 20px;
  opacity: 0;
  transition: opacity 1s;
}
.card p {
  max-width: 150px;
  margin-left: 20px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.card:hover p {
  opacity: 1;
}
.card p::after {
  content: "";
  width: 150px;
  height: 1px;
  display: block;
  margin-top: 20px;
  background: #ccc;
}
.fa-brands {
  opacity: 0;
  transition: opacity 1s;
}

.icons {
  position: absolute;
  fill: #333;
  height: 130px;
  top: 226px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.support-dates {
  border: none;

  padding: 5px;
}

.larger-Header {
  color: var(--color-primary);
  margin-bottom: 150px;
  font-size: 30px;
}

.sw-image h2 {
  color: var(--color-primary);
}

.support-page-contact-image img {
  width: 100%;
  max-width: 600px;
}
.support-page-contact-image {
  /* border: 1px solid var(--color-primary); */
  /* box-shadow: 0px 8px 15px rgba(46, 104, 229, 0.4); */
  padding: 20px;
  /* background-color: azure; */
}
.support-page-contact {
  width: 95%;
  margin: 0 auto;
}
.support-page-contact-form {
  display: flex;
  flex-direction: column;

  gap: 20px;

  text-align: left;
  /* border: 1px solid var(--color-primary); */
  /* box-shadow: 0px 8px 15px rgba(46, 104, 229, 0.4); */
  padding: 20px 0px;
}
.support-page-contact-form input {
  padding: 10px 15px;
  max-width: 500px;
  background-color: rgba(128, 128, 128, 0.281);
  border: 1px solid grey;
  letter-spacing: 1px;
}
.support-page-contact-form textarea {
  padding: 10px 15px;
  min-height: 200px;
  max-width: 500px;
  background-color: rgba(128, 128, 128, 0.281);

  letter-spacing: 2px;
  font-size: 14px;
}
.p-form {
  margin: 10px 0;
}
.form-div {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}
.support-page-contact-form button {
  width: 100%;
  max-width: 300px;
  padding: 10px 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}
.support-page-need-support {
  margin: 40px auto !important;
  margin-top: 50px;
  /* border: 1px solid white; */
}

.support-page-need-support h1 {
  margin-bottom: 50px;
  text-align: center;
}

.support-page-need-support > div {
  display: flex;
  margin-top: 75px;
  flex-wrap: wrap;
  width: 100%;
}
.support-page-need-support img {
  width: 100%;
  max-width: 450px;
}

.support-page-need-support p {
  /* border: 1px solid white; */
  max-width: 750px;
  padding: 30px 20px;
  letter-spacing: 1.5px;
  line-height: 1.8em;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .support-reasons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 1200px;
  }
}

@media only screen and (min-width: 900px) {
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .support-reasons {
    grid-template-columns: 1fr 1fr;
    max-height: 1200px;
  }
  .support {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 400px;
    overflow-y: hidden;
  }

  .support-text {
    display: flex;
    flex-direction: column;

    /* background-color: aliceblue; */
    box-shadow: 0px 5px 10px rgba(46, 104, 229, 0.4);
    color: black;
    text-align: left;
    padding: 20px;
    overflow-y: hidden;
    height: 100%;
  }

  .support-text h2 {
    text-align: left;
    padding: 0px 10px;
  }
  .support-ways {
    margin-bottom: 150px;
  }
  .support-ways-text {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .support-ways-unit {
    padding: 30px 40px;
    line-height: 1.8em;
    letter-spacing: 1px;

    transition: all 200ms ease-in-out;
    max-width: 400px;
    margin: 0 auto;
  }
  .support-ways-unit img {
    height: 74px;
    width: 74px;
    transition: transform 300ms ease-in-out;
  }
  .support-ways-unit img:hover {
    /* transform: rotateY(180deg); */
  }

  .sw-image:hover {
    background-color: #256eff;
    background-color: rgba(128, 0, 128, 0.25);
  }
  .support-ways-unit:hover {
    background-color: #2892d7;
    background-color: #256eff11;
    transform: translateY(-5px);
  }
  .support-image {
    height: 800px;
  }
  .extended {
    display: block;
  }
  .support-page-contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 1300px;
    padding: 10px;
  }
  .support-page-contact-form {
    background-color: transparent;
  }
}
