.support-container {
  padding: 55px;

  /* border: 1px solid purple; */
  margin: 50px 0;
}
.support-container h2 {
  color: var(--color-primary);
  font-weight: 700;
  letter-spacing: 1.5px;
  padding-bottom: 30px;
}
.support-container p {
  letter-spacing: 1px;
  line-height: 1.6em;
  padding-bottom: 30px;
}

.support-container i {
  font-size: 65px;
  color: var(--color-alternate);
}
.support-links {
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 600px;
  justify-content: space-evenly;
}

.support-links a {
  /* border: 1px solid rgba(128, 128, 128, 0.589); */
  padding: 15px;
}
