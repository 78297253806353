.features-sm-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.features-sm-container h2,
.features-lg-container h2 {
  color: var(--color-primary);
  font-weight: 900;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
  text-align: left;
}
.features-sm-container p,
.features-lg-container p {
  letter-spacing: 1px;
  line-height: 1.6em;
  color: white;
  margin: 30px 0px;
  letter-spacing: 1px;

  text-align: left;
  max-width: 800px;
}
.feature-icons {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.feature-icon img {
  width: 33%;
  max-width: 90px;
}
@media only screen and (min-width: 600px) {
  .feature-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .feature-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .feature-icon img {
    width: 90px;
  }
}
.features-lg-container {
  display: none;
}
@media only screen and (min-width: 901px) {
  .features-sm-container {
    display: none;
  }
  .features-lg-container {
    margin-top: 50px;
    display: block;
    padding: 15px;
    margin: 100px 0;
    /* border: 2px solid green; */
  }
  .features-slide {
    /* border: 2px solid green; */
    width: 100%;
    margin: 0 auto;
  }
  .slide-selection {
    /* border: 1px solid crimson; */
    padding: 20px 0;
  }
  .featured-selection {
    font-size: 18px;
    color: var(--color-alternate);
  }
  .featured-selection h3 {
    cursor: pointer;
    color: var(--color-primary);
  }
  .slide-selection-menu {
    display: flex;
    justify-content: space-evenly;
  }
  .slide-selection-menu > div {
    /* border: 2px solid orange; */
    width: 33.33%;
  }
  .features-lg-container p,
  .features-lg-container h2 {
    padding-bottom: 20px;
  }
  .slide-selection-bar {
    margin: 10px 0;
    height: 0.25rem;
    width: 33.33%;
    transition: 300ms ease-in-out;
    transform: translateX(0%);
    background: var(--color-alternate);
  }
  .slide-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--color-primary);
    height: 400px;
    transition: 500ms smooth;
  }
  .slide-image p {
    /* border: 2px solid red; */
    padding-top: 30px;
  }
  .slide-image img {
    width: 100%;
    max-width: 500px;
    padding: 20px;
  }
}
