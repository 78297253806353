.showcase-container {
  margin-top: 0px;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* border: 1px solid crimson; */
  padding: 0 15px;
}
.info-section {
  height: 100%;

  padding: 150px 0;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;
  animation: fade-in-up 1200ms;
}
.info-section-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 30px;
  line-height: 1.8em;
  letter-spacing: 1.5px;
}
.info-section-text h1 {
  font-weight: bolder;
  color: var(--color-primary);
  font-family: "Fira Sans", sans-serif;
}
.info-section-text p {
  font-size: 18px;
  font-weight: 700;
}
.info-section-button {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}
.image {
  height: 700px;
  padding: 20px;
  border-radius: 5px;
  /* border: 2px solid orange; */
  /* background-color: var(--color-primary); */
  animation: fade-in-down 1200ms;
  overflow-y: hidden;
}
.image img {
  width: 100%;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
}
.sm {
  display: none;
}
@media screen and (max-width: 900px) {
  .showcase-container {
    grid-template-columns: 1fr;
    margin: 50px;

    /* border: 1px solid green; */
  }
  .info-section {
    grid-template-rows: 2fr 1fr;
    padding: 10px;
  }
  .info-section-text {
    text-align: center;
    line-height: 1.8em;
    letter-spacing: 1px;
    font-size: 12px;
    justify-content: flex-start;
    /* border: 3px solid crimson; */
    height: fit-content;
    padding: 10px;
  }
  .info-section-text h1 {
    font-size: 23px;
    line-height: 1.3em;
  }

  .info-section-text p {
    font-size: 15px;
  }
  .info-section-button {
    /* border: 1px solid green; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .sm {
    display: block;
  }
  .sm img {
    width: 100%;
    max-width: 400px;
  }
  .lg {
    display: none;
  }
  .image {
    height: fit-content;
  }
}
