@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");
.nav-box {
  transition: all 0.5s ease-in-out;
}
.nav-contain {
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;
  /* border: 2px solid green; */
  padding: 0;

  /* transition: all 0.5s ease-in-out; */
}
.nav-toggle-container {
  position: static;
  display: none;
  /* border: 1px solid white; */
  position: absolute;
  left: 0;
  top: 0px;
  /* width: 50px;
  height: 65px; */
}
.nav-toggle-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 0 0 30px 0;
  color: var(--color-primary0);
  display: flex;
  align-items: top;
  padding-top: 3px;
  padding-left: 2px;
  background-color: grey;
}
.fas.fa-toggle-on.fa-3x,
.fas.fa-toggle-off.fa-3x {
  /* border: 1px solid green; */
  width: 100px;
  height: 50px !important;
  font-size: 44px;
}
.navbar-container {
  display: flex;

  justify-content: space-between;
  font-family: "Lato", sans-serif;
  width: 100%;
  max-width: 1300px;
  padding: 15px;
}
.logo {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  gap: -10px;
}
.logo h2,
.logo a {
  font-weight: 900;
  font-family: "Fira Sans", sans-serif;
  font-size: auto;
  color: var(--color-primary);
}
.logo img {
  width: 70px;
}
.navbar-menu ul {
  padding: 0;
  list-style: none;
  display: flex;
  /* border: 1px solid white; */
  /* justify-content: space-between; */
  gap: 5px;
  align-items: center;
  text-align: center;
}
.navbar-menu li {
  display: table-cell;
  position: relative;
  padding: 15px 0;
}
.navbar-menu a,
.navbar-menu p {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  color: white;

  font-weight: 700;
  display: inline-block;
  padding: 15px 10px;
  position: relative;
}

.navbar-menu p:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: crimson;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
a:hover:after,
.navbar-menu p:hover:after {
  width: 100%;
  left: 0;
}
.navbar-container-sm {
  display: none;
}

.toggle-li {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* border: 2px solid green; */
}
.toggle-li small {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
}
.toggle-li img {
  height: 39px;
}

.support-page-need-support {
  padding: 20px 0px;
  text-align: left;
  color: white;
}
@media only screen and (min-width: 1176px) {
  .nav-toggle-container {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    /* border: 2px solid white; */
  }
  .nav-contain {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-container {
    display: none;
  }
  .navbar-container-sm {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    /* border: 1px solid green; */
    width: 100%;
  }
  .logo h2 {
    font-weight: 700;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
  }
  .logo img {
    width: 40px;
  }
}
