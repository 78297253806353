@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");

.modal-sm {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: black;
  font-family: "Fira Sans", sans-serif;
  z-index: 1;
  font-size: larger;
  letter-spacing: 1px;
  animation: menu-show 800ms ease-in-out;
  transition: 600ms ease-in-out;
  color: white;
  /* border: 2px solid green; */
}
.modal-sm a {
  color: white;
}
.menu-modal {
  height: 80%;
}
.menu-modal ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.menu-modal i {
  border: 2px solid white;
  padding: 5px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
@media only screen and (min-width: 900px) {
  .modal-sm {
    display: none;
  }
}
@keyframes menu-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
